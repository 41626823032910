import React, { useEffect } from 'react'
// import {Link} from 'gatsby'
import Helmet from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Layout from '../layouts'
import Button from '@material-ui/core/Button'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
// import { useQueryParam, StringParam } from 'use-query-params'

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 550,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    textAlign: 'center'
  }
}))

const SuccessPage = ({navigate}) => {
  const classes = useStyles()
  const location = useLocation()
  const {orderNumber} = queryString.parse(location.search)
  // const [sessionId] = useQueryParam('session_id', StringParam)

  useEffect(() => {
    if (!orderNumber) {
      navigate('/')
    }
  }, [])

  return (
    <Layout>
      <div className='pageContent'>
        <Helmet>
          <title>Thank You For Your Order | Signxperts</title>
        </Helmet>
        <Paper className={classes.paper}>
          <CheckCircleOutlineIcon style={{color: '#01b901', fontSize: 80}} />
          <Box marginBottom={4}>
            <Typography variant={'h3'}>Thanks for your order!</Typography>
            <Typography>Order Number: {orderNumber}</Typography>
          </Box>
          <Box marginBottom={5}>
            <Typography>A receipt will be emailed to you shortly. If you have any questions, please email <a href='mailto:support@signxperts.com'>support@signxperts.com</a>.</Typography>
          </Box>
          <Button variant={'contained'} color={'primary'} href='/'>
            Continue Shopping
          </Button>
        </Paper>
      </div>
    </Layout>
  )
}

export default SuccessPage
